import { useHistory } from "react-router-dom";
import { buttonDanger } from "./styles.js";

const NewMovement = ({ addMovement }) => {
  const history = useHistory();

  const handleAddNewMovementClick = (value) => {
    addMovement(value);
    history.push("/");
  };

  const handleCancelClick = () => {
    history.push("/");
  };

  return (
    <div className="grid h-full gap-4">
      <button type="button" onClick={() => handleAddNewMovementClick(0)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          className="w-48 mx-auto text-red-400"
          viewBox="0 0 16 16"
        >
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path d="M4.285 12.433a.5.5 0 0 0 .683-.183A3.498 3.498 0 0 1 8 10.5c1.295 0 2.426.703 3.032 1.75a.5.5 0 0 0 .866-.5A4.498 4.498 0 0 0 8 9.5a4.5 4.5 0 0 0-3.898 2.25.5.5 0 0 0 .183.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z" />
        </svg>
      </button>
      <button type="button" onClick={() => handleAddNewMovementClick(1)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          className="w-48 mx-auto text-yellow-400"
          viewBox="0 0 16 16"
        >
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path d="M4 10.5a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 0-1h-7a.5.5 0 0 0-.5.5zm3-4C7 5.672 6.552 5 6 5s-1 .672-1 1.5S5.448 8 6 8s1-.672 1-1.5zm4 0c0-.828-.448-1.5-1-1.5s-1 .672-1 1.5S9.448 8 10 8s1-.672 1-1.5z" />
        </svg>
      </button>
      <button type="button" onClick={() => handleAddNewMovementClick(2)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          className="w-48 mx-auto text-green-500"
          viewBox="0 0 16 16"
        >
          <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
          <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z" />
        </svg>
      </button>
      <div type="button" className="flex items-center">
        <button
          onClick={handleCancelClick}
          className={buttonDanger("mx-auto px-10")}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default NewMovement;
