import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { button, buttonDanger, buttonSuccess } from "./styles.js";

const NewFood = ({ addFood, recentFoods }) => {
  const history = useHistory();
  const handleFoodSubmit = data => {
    addFood(data.food);
    history.push("/");
  };

  const FoodForm = ({ handleFoodSubmit }) => {
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm();

    return (
      <form onSubmit={handleSubmit(handleFoodSubmit)}>
        <div className="mb-4">
          <label>
            <div className="text-lg text-gray-700 font-bold mb-2">Food</div>
            <input
              type="text"
              className="border p-2 rounded w-full lg:w-auto mb-2 outline-none text-lg text-gray-500"
              autoFocus
              autoComplete="off"
              {...register("food", { required: true })}
            />
          </label>
          {errors.food && (
            <div className="text-lg text-red-500 mb-2">
              This field is required
            </div>
          )}
        </div>
        <div className="grid grid-cols-2 lg:block">
          <button className={button("mr-2")}>Save</button>
          <Link to="/" className={buttonDanger()}>
            Cancel
          </Link>
        </div>
      </form>
    );
  };

  const RecentFoodForm = ({ food, handleFoodSubmit }) => {
    const { register, handleSubmit } = useForm();

    return (
      <form
        onSubmit={handleSubmit(handleFoodSubmit)}
        className="inline-block mr-2 mb-2"
      >
        <input type="hidden" defaultValue={food} {...register("food")} />
        <button className={buttonSuccess()}>{food}</button>
      </form>
    );
  };

  return (
    <div className="space-y-8">
      <FoodForm handleFoodSubmit={handleFoodSubmit} />

      {recentFoods.length > 0 && (
        <div className="">
          <div className="text-lg text-gray-700 font-bold mb-2">Quick add</div>
          <div className="border pt-2 pl-2 pr-2 lg:inline-block">
            {recentFoods.map(recentFood => (
              <RecentFoodForm
                key={recentFood}
                food={recentFood}
                handleFoodSubmit={handleFoodSubmit}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default NewFood;
