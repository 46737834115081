import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { parseISO, formatISO, subHours, differenceInMinutes } from "date-fns";
import useLocalStorage from "./useLocalStorage";
import { h1, button, buttonSecondary, link } from "./styles.js";
import Item from "./Item";
import NewMovement from "./NewMovement";
import NewFood from "./NewFood";
import Triggers from "./Triggers";

function App() {
  const generateId = () => {
    window.currentId = window.currentId || 1;
    const id = window.currentId;
    window.currentId += 1;
    return id;
  };

  const [items, setItems] = useLocalStorage("items", [
    {
      id: generateId(),
      type: "food",
      label: "Carrots",
      createdAt: formatISO(subHours(new Date(), 24)),
    },
    {
      id: generateId(),
      type: "movement",
      value: 0,
      createdAt: formatISO(subHours(new Date(), 23)),
    },
    {
      id: generateId(),
      type: "food",
      label: "Eggs",
      createdAt: formatISO(subHours(new Date(), 23)),
    },
    {
      id: generateId(),
      type: "movement",
      value: 1,
      createdAt: formatISO(subHours(new Date(), 18)),
    },
    {
      id: generateId(),
      type: "food",
      label: "Potatoes",
      createdAt: formatISO(subHours(new Date(), 17)),
    },
    {
      id: generateId(),
      type: "movement",
      value: 2,
      createdAt: formatISO(subHours(new Date(), 12)),
    },
  ]);

  const addFood = (label) =>
    setItems(
      items.concat({
        id: generateId(),
        type: "food",
        label: label,
        createdAt: formatISO(new Date()),
      })
    );

  const addMovement = (value) =>
    setItems(
      items.concat({
        id: generateId(),
        type: "movement",
        value: value,
        createdAt: formatISO(new Date()),
      })
    );

  const removeItem = (item) => setItems(items.filter((i) => i.id !== item.id));

  const itemsWithGaps = () => {
    const result = [];
    for (let item of items) {
      const previousItem = result[result.length - 1];

      if (previousItem) {
        const isTooSoon =
          differenceInMinutes(
            parseISO(item.createdAt),
            parseISO(previousItem.createdAt)
          ) <= 5;

        if (!isTooSoon) {
          result.push({
            type: "gap",
            startTime: previousItem.createdAt,
            endTime: item.createdAt,
          });
        }
      }

      result.push(item);
    }

    return result;
  };

  return (
    <Router>
      <div className="container h-full mx-auto p-3 flex flex-col mb-10">
        <div className="flex mb-10">
          <div className="flex-auto flex items-center">
            <Link to="/">
              <h1 className={h1()}>IBS Food Diary</h1>
            </Link>
          </div>
          <div className="flex items-center">
            <Link to="/triggers" className={link()}>
              Triggers
            </Link>
          </div>
        </div>
        <Switch>
          <Route exact path="/">
            <div className="mb-12">
              <div className="grid grid-cols-2 lg:block">
                <Link to="/foods/new" className={button("mr-2")}>
                  Add food
                </Link>
                <Link to="/movements/new" className={buttonSecondary()}>
                  Add movement
                </Link>
              </div>
            </div>
            <div className="grid gap-1 mb-8">
              {itemsWithGaps()
                .reverse()
                .map((item, index) => (
                  <Item key={index} item={item} removeItem={removeItem} />
                ))}
            </div>
          </Route>
          <Route exact path="/triggers">
            <Triggers items={items} />
          </Route>
          <Route exact path="/movements/new">
            <NewMovement addMovement={addMovement} />
          </Route>
          <Route exact path="/foods/new">
            <NewFood
              addFood={addFood}
              recentFoods={[
                ...new Set(
                  items.filter((i) => i.type === "food").map((i) => i.label)
                ),
              ].reverse()}
            />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
