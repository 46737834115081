import classNames from "classnames";

export const h1 = () => "text-4xl text-gray-600 font-bold";
export const h2 = () => "text-3xl text-gray-700";
export const h3 = () => "text-2xl text-gray-700";

const defaultButtonStyles =
  "inline-block py-3 px-4 rounded font-bold text-left shadow";

export const button = (extras) =>
  classNames(defaultButtonStyles, "bg-blue-500 text-white", extras);
export const buttonSecondary = (extras) =>
  classNames(defaultButtonStyles, "bg-gray-500 text-white", extras);
export const buttonDanger = (extras) =>
  classNames(defaultButtonStyles, "bg-red-500 text-white", extras);
export const buttonSuccess = (extras) =>
  classNames(defaultButtonStyles, "bg-green-500 text-white", extras);

export const link = (extras) =>
  classNames("text-blue-500", "text-lg", "underline", extras);
