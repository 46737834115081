import { parseISO, format } from "date-fns";
import GapItem from "./GapItem.jsx";

const Item = ({ item, removeItem }) => {
  const foodIcon = () => {
    return (
      <div className="text-gray-300 mr-2">
        <svg
          aria-hidden="true"
          role="img"
          className="iconify iconify--mdi"
          width="32"
          height="32"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 24 24"
        >
          <path
            d="M3 3a1 1 0 0 0-1 1v5.5c0 1.69 1.03 3.13 2.5 3.72v6.28A1.5 1.5 0 0 0 6 21a1.5 1.5 0 0 0 1.5-1.5v-6.28c1.47-.59 2.5-2.03 2.5-3.72V4a1 1 0 0 0-1-1a1 1 0 0 0-1 1v4a.5.5 0 0 1-.5.5A.5.5 0 0 1 7 8V4a1 1 0 0 0-1-1a1 1 0 0 0-1 1v4a.5.5 0 0 1-.5.5A.5.5 0 0 1 4 8V4a1 1 0 0 0-1-1m16.88 0c-.13 0-.26.09-.38.16L16 5.25V9h-4v2h1l1 10h6l1-10h1V9h-4V6.34l2.5-1.5c.5-.28.63-.84.34-1.34c-.21-.36-.58-.55-.96-.5z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    );
  };

  const movementIcon = () => {
    if (item.value === 0) {
      return (
        <div className="text-red-400 mr-2">
          <svg
            aria-hidden="true"
            role="img"
            className="iconify iconify--uil"
            width="32"
            height="32"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M8.36 15.33a1 1 0 0 0-.13 1.4a1 1 0 0 0 1.41.13a3.76 3.76 0 0 1 4.72 0a1 1 0 0 0 1.41-.13a1 1 0 0 0-.13-1.4a5.81 5.81 0 0 0-7.28 0zM9 11a1 1 0 1 0-1-1a1 1 0 0 0 1 1zm3-9a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8a8 8 0 0 1-8 8zm3-11a1 1 0 1 0 1 1a1 1 0 0 0-1-1z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      );
    } else if (item.value === 1) {
      return (
        <div className="text-yellow-400 mr-2">
          <svg
            aria-hidden="true"
            role="img"
            width="32"
            height="32"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M9 11a1 1 0 1 0-1-1a1 1 0 0 0 1 1zm6 3H9a1 1 0 0 0 0 2h6a1 1 0 0 0 0-2zm0-5a1 1 0 1 0 1 1a1 1 0 0 0-1-1zm-3-7a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8a8 8 0 0 1-8 8z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      );
    } else if (item.value === 2) {
      return (
        <div className="text-green-500 mr-2">
          <svg
            aria-hidden="true"
            role="img"
            width="32"
            height="32"
            preserveAspectRatio="xMidYMid meet"
            viewBox="0 0 24 24"
          >
            <path
              d="M14.36 14.23a3.76 3.76 0 0 1-4.72 0a1 1 0 0 0-1.28 1.54a5.68 5.68 0 0 0 7.28 0a1 1 0 1 0-1.28-1.54zM9 11a1 1 0 1 0-1-1a1 1 0 0 0 1 1zm6-2a1 1 0 1 0 1 1a1 1 0 0 0-1-1zm-3-7a10 10 0 1 0 10 10A10 10 0 0 0 12 2zm0 18a8 8 0 1 1 8-8a8 8 0 0 1-8 8z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      );
    }
  };

  const icon = () => {
    if (item.type === "food") {
      return foodIcon();
    } else if (item.type === "movement") {
      return movementIcon();
    }
  };

  const movementLabel = () => {
    if (item.value === 0) {
      return "Bad";
    } else if (item.value === 1) {
      return "Ok";
    } else if (item.value === 2) {
      return "Great";
    }
  };

  const label = () => {
    if (item.type === "food") {
      return item.label;
    } else if (item.type === "movement") {
      return movementLabel();
    }
  };

  const handleRemoveItemClick = () => {
    if (window.confirm("Are you sure?")) {
      removeItem(item);
    }
  };

  if (item.type === "gap") {
    return <GapItem item={item} />;
  }

  return (
    <div>
      <div className="rounded p-2 flex items-center">
        {icon()}
        <div className="text-md text-gray-600 font-bold flex-grow">
          {label()}
        </div>
        <div className="text-sm text-gray-500">
          {format(parseISO(item.createdAt), "PPP HH:mm")}
        </div>
        <div>
          <button
            type="button"
            className="inline-block w-full h-full text-red-500 px-3"
            onClick={handleRemoveItemClick}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              viewBox="0 0 16 16"
            >
              <path d="M1.293 1.293a1 1 0 0 1 1.414 0L8 6.586l5.293-5.293a1 1 0 1 1 1.414 1.414L9.414 8l5.293 5.293a1 1 0 0 1-1.414 1.414L8 9.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L6.586 8 1.293 2.707a1 1 0 0 1 0-1.414z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Item;
