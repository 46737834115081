import { parseISO, differenceInHours } from "date-fns";
import { immutable as uniqueImmutable } from "array-unique";

const Triggers = ({ items }) => {
  if (items.length === 0) {
    return (
      <div className="text-gray-700 text-lg">
        We can't find any triggers yet. Keep logging in your diary and we'll
        find something!
      </div>
    );
  }

  const movements = items.filter((i) => i.type === "movement" && i.value === 0);
  const foods = items.filter((i) => i.type === "food");
  const possibilities = foods.filter((food) => {
    const foodCreatedAt = parseISO(food.createdAt);

    const matchingMovements = movements.filter((movement) => {
      const difference = differenceInHours(
        foodCreatedAt,
        parseISO(movement.createdAt)
      );
      return difference >= -24 && difference <= -1;
    });

    return matchingMovements.length > 0;
  });

  const uniquePossibilities = uniqueImmutable(
    possibilities.map((p) => p.label)
  );

  return (
    <div className="border-t border-l border-r rounded-lg">
      {uniquePossibilities.map((possibility) => (
        <div key={possibility} className="border-b p-3">
          {possibility}
        </div>
      ))}
    </div>
  );
};

export default Triggers;
