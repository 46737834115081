import { differenceInSeconds, formatDistance } from "date-fns";
import { parseISO } from "date-fns";

const GapItem = ({ item }) => {
  const maxSeconds = 86400;
  const startTime = parseISO(item.startTime);
  const endTime = parseISO(item.endTime);

  const height = () => {
    const seconds = differenceInSeconds(endTime, startTime);
    const percent = seconds / maxSeconds;

    return Math.max(percent * 150, 20);
  };

  return (
    <div className="p-2 pl-0 flex items-center">
      <div
        className="w-1 bg-pink-300 rounded mr-3 ml-5"
        style={{ height: `${height()}px` }}
      ></div>
      <div className="text-gray-500 text-sm">
        {formatDistance(startTime, endTime)}
      </div>
    </div>
  );
};

export default GapItem;
